import { inject, Injectable } from '@angular/core';
import { Client, Conversation, Paginator } from '@twilio/conversations';
import {
	combineLatest,
	defer,
	EMPTY,
	expand,
	finalize,
	forkJoin,
	from,
	map,
	MonoTypeOperatorFunction,
	Observable,
	of,
	reduce,
	repeat,
	ReplaySubject,
	shareReplay,
	Subject,
	switchMap,
	tap,
} from 'rxjs';

import { ChatCredentials } from '../models/chat/chat-credentials';
import { ChatMapper } from '../mappers/chat.mapper';
import { assertNonNullWithReturn } from '../utils/assert-non-null';
import { Chat, ConversationUser } from '../models/chat/chat';
import { AppError } from '../models/app-error';
import { type Session } from '../models/session';
import { ConsultationsApiService } from '../api/consultations.api';
import { conversationUserAttributesDtoSchema } from '../dtos/chat/conversation-user-attributes.dto';

import { ChatMeta } from '../models/chat/chat-meta';
import { chatIdAttributesSchema } from '../dtos/chat/chat-id-attributes';
import { catchHttpErrorResponse } from '../utils/rxjs/catch-http-error-response';
import { ChatCallMessage } from '../models/chat/chat-call-message';
import { isCallAttributes } from '../twilio/call-attributes';
import { UserBase } from '../models/user-base';

import { ChatApiService } from './chat-api.service';
import { AppConfig } from './app.config';

const MESSAGE_SENT_SOUND_SRC = 'assets/sounds/message-sent.mp3';

/** Type for 'initFailed' event callback. */
type TwilionInitFailedCallback = (error: {

	/** Error object. */
	error?: {

		/** Is terminated flag. */
		terminal: boolean;

		/** Error message. */
		message: string;
	};
}) => void;

/** Service to manage chats and messages. */
@Injectable({ providedIn: 'root' })
export class ChatService {
	private readonly chatApiService = inject(ChatApiService);

	private readonly consultationsApiService = inject(ConsultationsApiService);

	private readonly chatMapper = inject(ChatMapper);

	private readonly appConfig = inject(AppConfig);

	private readonly chatClientRefresher$ = new Subject<void>();

	private readonly chatsRefresher$ = new Subject<void>();

	private _chatClient$: Observable<Client> | null = null;

	private readonly messageSentAudio = new Audio(MESSAGE_SENT_SOUND_SRC);

	private readonly _incomingCallMessage$ = new ReplaySubject<ChatCallMessage>(1);

	/** Emits message when the app receives message about incoming call. */
	public readonly incomingCallMessage$ = this._incomingCallMessage$.asObservable();

	/**
		* Get chat.
		* @param id ID of the user with whom there is a conversation.
		*/
	public getChatWithUser(id: UserBase['id']): Observable<Chat> {
		return combineLatest([
			this.getChatClient(),
			this.chatApiService.generateConversationSidWithUser(id),
		]).pipe(
			switchMap(([client, sid]) => from(client.getConversationBySid(sid.conversation_sid)).pipe(
				switchMap(conversation => defer(() => conversation.updateLastReadMessageIndex(conversation.lastReadMessageIndex)).pipe(
					map(() => conversation),
				)),
				switchMap(conversation => this.fromConversation(conversation, client)),
			)),
		);
	}

	/** Create chat invitation link. */
	public createChatInvitationLink(): Observable<string> {
		return this.chatApiService.generateConversationSidWithUser().pipe(
			switchMap(chatMeta => this.chatApiService.generateInviteToken(chatMeta.id)),
			map(token => {
				const url = new URL('guest-chat', this.appConfig.originUrl);
				url.searchParams.append('inviteToken', token);
				return url.toString();
			}),
		);
	}

	/**
	 * Get chat by ID.
	 * @param id Chat id.
	 */
	public getChatById(id: Chat['id']): Observable<Chat> {
		return combineLatest([
			this.getChatClient(),
			this.getChatMetaById(id),
		]).pipe(
			switchMap(([client, chatMeta]) => from(client.getConversationBySid(chatMeta.twilioSid)).pipe(
				switchMap(conversation => defer(() => conversation.updateLastReadMessageIndex(conversation.lastReadMessageIndex)).pipe(
					map(() => conversation),
					switchMap(() => this.fromConversation(conversation, client)),
				)),
			)),
		);
	}

	/**
	 * Get meta information for a chat with a user.
	 * @param id User ID.
	 */
	public getMetaForChatWithUser(id: UserBase['id']): Observable<ChatMeta> {
		return this.chatApiService.generateChatMeta(id);
	}

	/**
	 * Get chat meta data.
	 * @param id Chat id.
	 */
	public getChatMetaById(id: ChatMeta['id']): Observable<ChatMeta> {
		return this.chatApiService.getChatMeta(id);
	}

	/**
	 * Returns Chat instance for a anonymous guest.
	 * @param token Access token for a guest.
	 * @throws AppError if token is invalid.
	 */
	public getChatForGuest(token: string): Observable<Chat> {
		return this.chatApiService.getGuestChatCredentials(token).pipe(
			switchMap(credentials => this.getChatClient(credentials.token).pipe(
				switchMap(client => from(client.getConversationBySid(credentials.sid)).pipe(
					switchMap(conversation => this.fromConversation(conversation, client)),
				)),
			)),
			catchHttpErrorResponse(error => {
				if (error.status === 401 || error.status === 400) {
					throw new AppError('Invalid token');
				}
				throw error;
			}),
		);
	}

	/**
	 * Get chat for a consultation.
	 * @param id Consultation ID.
	 */
	public getConsultationChat(id: Session['id']): Observable<Chat> {
		return combineLatest([
			this.getChatClient(),
			this.consultationsApiService.getConsultationChatSid(id),
		]).pipe(
			switchMap(([client, sid]) => from(client.getConversationBySid(sid)).pipe(
				switchMap(conversation => defer(() => conversation.updateLastReadMessageIndex(conversation.lastReadMessageIndex)).pipe(
					map(() => conversation),
				)),
				switchMap(conversation => this.fromConversation(conversation, client)),
			)),
		);
	}

	/**
	 * Get available chats.
	 * @param search Search filter.
	 */
	public getChats(search = ''): Observable<Chat[]> {
		const lowercaseSearch = search.toLowerCase();
		return this.getChatClient().pipe(
			switchMap(client => this.getAllConversations(client).pipe(
				map(conversations => conversations.filter(c => {
					const participant = c.participants[0];
					return UserBase.toFullName(participant)
						.toLowerCase()
						.includes(lowercaseSearch);
				})),
				repeat({ delay: () => this.chatsRefresher$ }),
			)),
		);
	}

	/** Reinitialize chat client. */
	public reinitializeChatClient(): void {
		this.chatClientRefresher$.next();
	}

	private getAllConversations(client: Client): Observable<Chat[]> {
		return defer(() => client.getSubscribedConversations()).pipe(
			expand(paginator => paginator.hasNextPage ? paginator.nextPage() : EMPTY),
			reduce<Paginator<Conversation>, Conversation[]>((acc, data) => acc.concat(data.items), []),
			switchMap(conversations => conversations.length === 0 ?
				of([]) :
				forkJoin(conversations.map(conversation => this.fromConversation(conversation, client)))),
			map(conversations => conversations.toSorted(Chat.compareFn)),
			map(conversations => conversations.filter(chat => chat.participants.at(0) !== undefined)),
		);
	}

	private getChatClient(token?: string): Observable<Client> {
		if (this._chatClient$) {
			return this._chatClient$;
		}
		const chatToken$ = (token ? of(token) : this.getAuthorizationToken()).pipe(
			repeat({ delay: () => this.chatClientRefresher$ }),
		);
		this._chatClient$ = chatToken$.pipe(
			switchMap(chatToken => this.initChatClient(chatToken)),
			finalize(() => {
				this._chatClient$ = null;
			}),

			// Client isn't changed, so we can share it between subscribers.
			// We manually update our client by handling events.
			shareReplay({ refCount: true, bufferSize: 1 }),
		);
		return this._chatClient$;
	}

	private initChatClient(token: string): Observable<Client> {
		return new Observable<Client>(subscriber => {
			const client = new Client(token);

			const onInitialized = (): void => {
				subscriber.next(client);
			};

			const onInitFailed: TwilionInitFailedCallback = ({ error }): void => {
				subscriber.error(new AppError(error?.message ?? 'Twilio client initialization failed'));
			};

			client.on('initialized', onInitialized);

			client.on('initFailed', onInitFailed);

			return () => {
				client.removeAllListeners();
			};
		}).pipe(
			this.initializeChatClientHandlers(),
		);
	}

	/** Get authorization token to connect to twilio client. */
	private getAuthorizationToken(): Observable<ChatCredentials['authToken']> {
		return this.chatApiService.getChatCredentials().pipe(
			map(({ authToken }) => authToken),
		);
	}

	private initializeChatClientHandlers(): MonoTypeOperatorFunction<Client> {
		return source$ => source$.pipe(
			tap(client => {
				const currentUser = conversationUserAttributesDtoSchema.parse(client.user.attributes);

				client.on('tokenAboutToExpire', () => {
					this.chatClientRefresher$.next();
				});

				client.on('tokenExpired', () => {
					this.chatClientRefresher$.next();
				});

				client.on('conversationUpdated', () => {
					this.chatsRefresher$.next();
				});

				client.on('connectionError', () => {
					this.chatClientRefresher$.next();
				});

				client.on('messageAdded', message => {
					this.messageSentAudio.play();

					if (message.author !== String(currentUser.id)) {
						if (isCallAttributes(message.attributes)) {
							this._incomingCallMessage$.next(ChatCallMessage.fromMessage(message));
						}
					}
				});
			}),
		);
	}

	private async fromConversation(conversation: Conversation, client: Client): Promise<Chat> {
		const participants = await conversation.getParticipants();
		const users = await Promise.all(participants.map(p => p.getUser()));
		const mappedUsers = users
			.map(user => {
				try {
					const parsedDto = conversationUserAttributesDtoSchema.parse(user.attributes);
					return this.chatMapper.fromConversationUserAttributesDto(parsedDto);
				} catch (e) {
					return null;
				}
			})
			.filter((user): user is ConversationUser => user !== null);

		const me = assertNonNullWithReturn(mappedUsers.find(user => user.id === client.user.identity));
		const participator = mappedUsers.find(user => user.id !== client.user.identity);

		const unreadMessagesCount = conversation.lastReadMessageIndex !== null ?
			((await conversation.getUnreadMessagesCount()) ?? 0) :
			await conversation.getMessagesCount();

		const rawLatestMessage = (await conversation.getMessages(1, undefined, 'backwards')).items[0];
		const latestMessage: Chat['latestMessage'] = rawLatestMessage ? {
			body: rawLatestMessage.body ?? '',
			date: rawLatestMessage.dateUpdated ?? null,
		} : undefined;

		const name = participator !== undefined ? UserBase.toDetailedName(participator) : '';

		const attributesWithChatId = chatIdAttributesSchema.parse(conversation.attributes);

		return {
			id: attributesWithChatId.chat_id,
			conversation,
			name,
			me,
			participants: participator !== undefined ? [participator] : [],
			unreadMessagesCount,
			latestMessage,
		};
	}
}
